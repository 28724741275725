import FooterLinks from "./components/FooterLinks";
import IntroSection from "./components/IntroSection";
import ReactGA from "react-ga4";

function App() {
  return (
    <div className="dark:bg-slate-800 dark:text-[#d4d5d5] flex flex-col font-Josefin h-screen lg:text-4xl md:text-3xl pb-2 pt-48 px-5 sm:text-2xl text-lg text-[#151a1e]">
      <IntroSection />
      <FooterLinks />
    </div>
  );
}

const TRACKING_ID = "G-JWXP4LSKM9";
ReactGA.initialize(TRACKING_ID);

export default App;
