import { FaGithub, FaGitlab, FaLinkedinIn, FaQuestion } from "react-icons/fa";

function FooterLinks() {
  const socialLinks = [
    {
      icon: <FaLinkedinIn />,
      url: "https://www.linkedin.com/in/trevorphillips78/",
    },
    { icon: <FaGithub />, url: "https://github.com/trevorphillipscoding" },
    { icon: <FaGitlab />, url: "https://gitlab.com/trevorphillipscoding" },
    {
      icon: <FaQuestion />,
      url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    },
  ];

  return (
    <div className="self-center">
      <footer className="flex gap-x-3">
        {socialLinks.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noreferrer">
            {link.icon}
          </a>
        ))}
      </footer>
    </div>
  );
}

export default FooterLinks;
